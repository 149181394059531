import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faBuilding, faLaptopHouse, faSun, faMoon } from '@fortawesome/free-solid-svg-icons';


const Experience = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const backgroundImage = isDarkMode ? '/images/bgdark.jpg' : '/images/bg.jpg';
  const logoImage = isDarkMode ? '/images/ydark.jpg' : '/images/y.jpg';

  return (
    <div className={`relative flex flex-col min-h-screen ${isDarkMode ? 'bg-gray-900 text-lightcoral' : 'bg-gray-100 text-black'}`}>
      <div className="absolute inset-0 bg-cover bg-center bg-no-repeat filter blur-md" style={{ backgroundImage: `url(${backgroundImage})` }}></div>
      
      {/* Navbar */}
      <nav className={`flex items-center justify-between py-4 px-4 ${isDarkMode ? 'bg-gray-800' : 'bg-pink'} text-white z-50 relative`}>
        <div className="flex items-center justify-between w-full md:w-auto">
          <Link to="/" className="text-xl font-bold">
            <img
              src={logoImage}
              alt="Logo"
              style={{ width: '50px' }}
            />
          </Link>

          {/* Hamburger Menu Icon for mobile */}
          <button
            onClick={toggleMenu}
            className="md:hidden ml-auto focus:outline-none"
          >
            <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} size="3x" />
          </button>
        </div>

        {/* Links - shown only when menu is open on mobile or always on desktop */}
        <ul
          className={`flex-col md:flex-row md:flex md:items-center md:space-x-4 space-y-4 md:space-y-0 mt-4 md:mt-0 ${
            isMenuOpen ? 'flex' : 'hidden'
          }`}
        >
             <li>
            <Link
              to="/"
              className={`px-6 py-2 rounded-full ${isDarkMode ? 'bg-lightcoral' : 'bg-lightcoral'} hover:bg-coral text-white transition-colors duration-300`}
              onClick={toggleMenu}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/projects"
              className={`px-6 py-2 rounded-full ${isDarkMode ? 'bg-lightcoral' : 'bg-lightcoral'} hover:bg-coral text-white transition-colors duration-300`}
              onClick={toggleMenu}
            >
              Projects
            </Link>
          </li>
          <li>
            <Link
              to="/education"
              className={`px-6 py-2 rounded-full ${isDarkMode ? 'bg-lightcoral' : 'bg-lightcoral'} hover:bg-coral text-white transition-colors duration-300`}
              onClick={toggleMenu}
            >
              Education
            </Link>
          </li>
        
          <li>
            <Link
              to="/skills"
              className={`px-6 py-2 rounded-full ${isDarkMode ? 'bg-lightcoral' : 'bg-lightcoral'} hover:bg-coral text-white transition-colors duration-300`}
              onClick={toggleMenu}
            >
              Skills
            </Link>
          </li>
          <li>
            <Link
              to="/links"
              className={`px-6 py-2 rounded-full ${isDarkMode ? 'bg-lightcoral' : 'bg-lightcoral'} hover:bg-coral text-white transition-colors duration-300`}
              onClick={toggleMenu}
            >
              Get in touch
            </Link>
          </li>
        </ul>

        {/* Theme Toggle Button */}
        <button
          onClick={toggleTheme}
          className="hidden md:block ml-4 px-4 py-2 rounded-full"
        >
          <FontAwesomeIcon icon={isDarkMode ? faSun : faMoon} size="2x" className={`${isDarkMode ? 'text-lightcoral' : 'text-lightcoral'}`} />
        </button>
      </nav>

      {/* Background Image */}
      <div 
        className="absolute inset-0 bg-cover bg-center bg-no-repeat filter blur-md"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>

      {/* Content */}
      <div className="relative container mx-auto px-6 py-8 bg-opacity-80 rounded-lg shadow-lg z-10">
        <h1 className="text-4xl font-bold text-center mb-8">Work Experience</h1>
        <div className="space-y-8">         
          <div className="p-6 rounded-lg shadow-2xl flex justify-between items-center">
            <div>
              <h2 className="text-2xl font-semibold mb-2">System Analyst, Wisdom High School</h2>
              <p className="text-lg mb-2">August 2023 - Present</p>
              <p className="text-lg">
                • Drafted documents, reports, spreadsheets, presentations, and other written materials.
                <br />
                • Reformatted, converted, and merged documents to conform to the school's specifications.
                <br />
                • Transcribed handwritten documents and audio recordings to digital documents.
                <br />
                • Proofread completed work and corrected spelling, punctuation, and grammatical errors.
                <br />
                • Managed and maintained the physical and digital document filing system.
                <br />
                • Performed general office duties such as copying, scanning, and printing.
                <br />
                • Generated staff ID cards and student ID cards using Gateview software.
                <br />
                • Managed ESI for teachers using dedicated software.
              </p>
            </div>
            <FontAwesomeIcon icon={faLaptopHouse} className="text-[60px]" />
          </div>
          <div className="p-6 rounded-lg shadow-2xl flex justify-between items-center">
            <div>
              <h2 className="text-2xl font-semibold mb-2">Campus Lead, Hyderabad, India</h2>
              <p className="text-lg mb-2">Nov 2021 - June 2023</p>
              <p className="text-lg">
                • Led a team of 10 members in organizing campus events, coordinating activities, and fostering a sense of community among students.
                <br />
                • Spearheaded initiatives to organize industrial visits.
                <br />
                • Collaborated with faculty and administration to address student concerns and improve campus life.
                <br />
                • Developed and implemented strategies to increase student engagement and participation in campus activities.
                <br />
                • Demonstrated strong communication, organizational, and problem-solving skills in a dynamic environment.
              </p>
            </div>
            <FontAwesomeIcon icon={faBuilding} className="text-[60px]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
