import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon, faTimes, faBars } from '@fortawesome/free-solid-svg-icons';

const Skills = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const backgroundImage = isDarkMode ? '/images/bgdark.jpg' : '/images/bg.jpg';
  const logoImage = isDarkMode ? '/images/ydark.jpg' : '/images/y.jpg';

  return (
    <div className={`relative flex flex-col min-h-screen ${isDarkMode ? 'bg-gray-900 text-lightcoral' : 'bg-gray-100 text-black'}`}>
      <div className="absolute inset-0 bg-cover bg-center bg-no-repeat filter blur-md" style={{ backgroundImage: `url(${backgroundImage})` }}></div>
      
      {/* Navbar */}
      <nav className={`flex items-center justify-between py-4 px-4 ${isDarkMode ? 'bg-gray-800' : 'bg-pink'} text-white z-50 relative`}>
        <div className="flex items-center justify-between w-full md:w-auto">
          <Link to="/" className="text-xl font-bold">
            <img
              src={logoImage}
              alt="Logo"
              style={{ width: '50px' }}
            />
          </Link>

          {/* Hamburger Menu Icon for mobile */}
          <button
            onClick={toggleMenu}
            className="md:hidden ml-auto focus:outline-none"
          >
            <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} size="3x" />
          </button>
        </div>

        {/* Links - shown only when menu is open on mobile or always on desktop */}
        <ul
          className={`flex-col md:flex-row md:flex md:items-center md:space-x-4 space-y-4 md:space-y-0 mt-4 md:mt-0 ${
            isMenuOpen ? 'flex' : 'hidden'
          }`}
        >
          <li>
            <Link
              to="/projects"
              className={`px-6 py-2 rounded-full ${isDarkMode ? 'bg-lightcoral' : 'bg-lightcoral'} hover:bg-coral text-white transition-colors duration-300`}
              onClick={toggleMenu}
            >
              Projects
            </Link>
          </li>
          <li>
            <Link
              to="/education"
              className={`px-6 py-2 rounded-full ${isDarkMode ? 'bg-lightcoral' : 'bg-lightcoral'} hover:bg-coral text-white transition-colors duration-300`}
              onClick={toggleMenu}
            >
              Education
            </Link>
          </li>
          <li>
            <Link
              to="/experience"
              className={`px-6 py-2 rounded-full ${isDarkMode ? 'bg-lightcoral' : 'bg-lightcoral'} hover:bg-coral text-white transition-colors duration-300`}
              onClick={toggleMenu}
            >
              Experience
            </Link>
          </li>
          <li>
            <Link
              to="/skills"
              className={`px-6 py-2 rounded-full ${isDarkMode ? 'bg-lightcoral' : 'bg-lightcoral'} hover:bg-coral text-white transition-colors duration-300`}
              onClick={toggleMenu}
            >
              Skills
            </Link>
          </li>
          <li>
            <Link
              to="/links"
              className={`px-6 py-2 rounded-full ${isDarkMode ? 'bg-lightcoral' : 'bg-lightcoral'} hover:bg-coral text-white transition-colors duration-300`}
              onClick={toggleMenu}
            >
              Get in touch
            </Link>
          </li>
        </ul>

        {/* Theme Toggle Button */}
        <button
          onClick={toggleTheme}
          className="hidden md:block ml-4 px-4 py-2 rounded-full"
        >
          <FontAwesomeIcon icon={isDarkMode ? faSun : faMoon} size="2x" className={`${isDarkMode ? 'text-lightcoral' : 'text-lightcoral'}`} />
        </button>
      </nav>
      
      {/* Content */}
      <div className="relative container mx-auto px-6 py-8 z-10">
        <h1 className="text-4xl font-bold mb-6 text-lightcoral" >My Skills</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-gray-800 bg-opacity-50 text-white' : 'bg-lightcoral text-black bg-opacity-70'} ${isDarkMode ? 'bg-opacity-20' : 'bg-opacity-100'}`}>
            <h2 className="text-2xl font-semibold">Soft Skills</h2>
            <ul className="mt-2 text-lg">
              <li>• Leadership</li>
              <li>• Communication</li>
              <li>• Collaboration</li>
              <li>• Team Work</li>
              <li>• Proactive</li>
              <li>• Time Management</li>
            </ul>
          </div>
          <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-gray-800 bg-opacity-50 text-white' : 'bg-lightcoral text-black bg-opacity-70'} ${isDarkMode ? 'bg-opacity-20' : 'bg-opacity-100'}`}>
            <h2 className="text-2xl font-semibold">Programming Languages</h2>
            <ul className="mt-2 text-lg">
              <li>• JavaScript</li>
              <li>• C</li>
            </ul>
          </div>
          <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-gray-800 bg-opacity-50 text-white' : 'bg-lightcoral text-black bg-opacity-70'} ${isDarkMode ? 'bg-opacity-20' : 'bg-opacity-100'}`}>
            <h2 className="text-2xl font-semibold">Frontend</h2>
            <ul className="mt-2 text-lg">
              <li>• React</li>
              <li>• HTML</li>
              <li>• CSS</li>
            </ul>
          </div>
          <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-gray-800 bg-opacity-50 text-white' : 'bg-lightcoral text-black bg-opacity-70'} ${isDarkMode ? 'bg-opacity-20' : 'bg-opacity-100'}`}>
            <h2 className="text-2xl font-semibold">Backend</h2>
            <ul className="mt-2 text-lg">
              <li>• Express.js</li>
              <li>• Node.js</li>
            </ul>
          </div>
          <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-gray-800 bg-opacity-50 text-white' : 'bg-lightcoral text-black bg-opacity-70'} ${isDarkMode ? 'bg-opacity-20' : 'bg-opacity-100'}`}>
            <h2 className="text-2xl font-semibold">Databases</h2>
            <ul className="mt-2 text-lg">
              <li>• MongoDB</li>
              <li>• Basics of PostgreSQL</li>
            </ul>
          </div>
          <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-gray-800 bg-opacity-50 text-white' : 'bg-lightcoral text-black bg-opacity-70'} ${isDarkMode ? 'bg-opacity-20' : 'bg-opacity-100'}`}>
            <h2 className="text-2xl font-semibold">Deployment/CI/CD</h2>
            <ul className="mt-2 text-lg">
              <li>• Basics of AWS</li>
            </ul>
          </div>
          <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-gray-800 bg-opacity-50 text-white' : 'bg-lightcoral text-black bg-opacity-70'} ${isDarkMode ? 'bg-opacity-20' : 'bg-opacity-100'}`}>
            <h2 className="text-2xl font-semibold">Other Tools</h2>
            <ul className="mt-2 text-lg">
              <li>• GitHub</li>
              <li>• VSCode</li>
              <li>• Postman</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
