import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Projects from './components/Projects';
import Education from './components/Education';
import Experience from './components/Experience';
import Links from './components/Links';
import Skills from './components/Skills';
// import Navbar from './components/Navbar';

const App = () => (
  <Router>
    {/* <Navbar /> */}
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/education" element={<Education />} />
      <Route path="/experience" element={<Experience />} />
      <Route path="/links"element={<Links/>}/>
      <Route path="/skills"element={<Skills/>}/>
  
    </Routes>
  </Router>
);

export default App;
