import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon, faTimes, faBars } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Links = () => {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleTheme = () => {
        setIsDarkMode(!isDarkMode);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const backgroundImage = isDarkMode ? '/images/bgdark.jpg' : '/images/bg.jpg';
    const logoImage = isDarkMode ? '/images/ydark.jpg' : '/images/y.jpg';

    return (
        <div className={`relative flex flex-col min-h-screen ${isDarkMode ? 'bg-gray-900 text-lightcoral' : 'bg-gray-100 text-black'}`}>
            <div className="absolute inset-0 bg-cover bg-center bg-no-repeat filter blur-md" style={{ backgroundImage: `url(${backgroundImage})` }}></div>

            {/* Navbar */}
            <nav className={`flex items-center justify-between py-4 px-4 ${isDarkMode ? 'bg-gray-800' : 'bg-pink'} text-white z-50 relative`}>
                <div className="flex items-center justify-between w-full md:w-auto">
                    <Link to="/" className="text-xl font-bold">
                        <img
                            src={logoImage}
                            alt="Logo"
                            style={{ width: '50px' }}
                        />
                    </Link>

                    {/* Hamburger Menu Icon for mobile */}
                    <button
                        onClick={toggleMenu}
                        className="md:hidden ml-auto focus:outline-none"
                    >
                        <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} size="3x" />
                    </button>
                </div>

                {/* Links - shown only when menu is open on mobile or always on desktop */}
                <ul
                    className={`flex-col md:flex-row md:flex md:items-center md:space-x-4 space-y-4 md:space-y-0 mt-4 md:mt-0 ${isMenuOpen ? 'flex' : 'hidden'
                        }`}
                >
                    <li>
                        <Link
                            to="/"
                            className={`px-6 py-2 rounded-full ${isDarkMode ? 'bg-lightcoral' : 'bg-lightcoral'} hover:bg-coral text-white transition-colors duration-300`}
                            onClick={toggleMenu}
                        >
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/projects"
                            className={`px-6 py-2 rounded-full ${isDarkMode ? 'bg-lightcoral' : 'bg-lightcoral'} hover:bg-coral text-white transition-colors duration-300`}
                            onClick={toggleMenu}
                        >
                            Projects
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/education"
                            className={`px-6 py-2 rounded-full ${isDarkMode ? 'bg-lightcoral' : 'bg-lightcoral'} hover:bg-coral text-white transition-colors duration-300`}
                            onClick={toggleMenu}
                        >
                            Education
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/skills"
                            className={`px-6 py-2 rounded-full ${isDarkMode ? 'bg-lightcoral' : 'bg-lightcoral'} hover:bg-coral text-white transition-colors duration-300`}
                            onClick={toggleMenu}
                        >
                            Skills
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/experience"
                            className={`px-6 py-2 rounded-full ${isDarkMode ? 'bg-lightcoral' : 'bg-lightcoral'} hover:bg-coral text-white transition-colors duration-300`}
                            onClick={toggleMenu}
                        >
                            Experience
                        </Link>
                    </li>
                </ul>

                {/* Theme Toggle Button */}
                <button
                    onClick={toggleTheme}
                    className="hidden md:block ml-4 px-4 py-2 rounded-full"
                >
                    <FontAwesomeIcon icon={isDarkMode ? faSun : faMoon} size="3x" className={`${isDarkMode ? 'text-lightcoral' : 'text-lightcoral'}`} />
                </button>
            </nav>

            {/* Circular Links Content */}
            <div className={`relative ml-4 sm:ml-8 mt-12 w-96 h-96 sm:w-[28rem] sm:h-[28rem] p-8 bg-lightcoral rounded-full shadow-lg flex items-center justify-center text-center transition-colors duration-300`}>
            <div>
                    <h1 className="text-4xl sm:text-5xl font-bold mb-6 text-white">Links</h1>
                    <ul className="space-y-4">
                        <li>
                            <a
                                href="https://github.com/Tabassuminggg"
                                className="flex items-center space-x-4 text-white hover:text-pink"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon icon={faGithub} size="3x" />
                                <span className="text-xl sm:text-3xl">GitHub</span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.linkedin.com/in/yaseera/"
                                className="flex items-center space-x-4 text-white hover:text-pink"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon icon={faLinkedin} size="3x" />
                                <span className="text-xl sm:text-3xl">LinkedIn</span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://x.com/YaseeraTabassum"
                                className="flex items-center space-x-4 text-white hover:text-pink"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon icon={faTwitter} size="3x" />
                                <span className="text-xl sm:text-3xl">Twitter</span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="mailto:yaseeratabassum@gmail.com"
                                className="flex items-center space-x-4 text-white hover:text-pink"
                            >
                                <FontAwesomeIcon icon={faEnvelope} size="3x" />
                                <span className="text-xl sm:text-3xl">Email</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Links;
