import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon, faTimes, faBars } from '@fortawesome/free-solid-svg-icons';

const Home = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const backgroundImage = isDarkMode ? '/images/bgdark.jpg' : '/images/bg.jpg';
  const logoImage = isDarkMode ? '/images/ydark.jpg' : '/images/y.jpg';

  return (
    <div className={`relative flex flex-col min-h-screen ${isDarkMode ? 'bg-gray-900 text-lightcoral' : 'bg-gray-100 text-black'}`}>
      <div className="absolute inset-0 bg-cover bg-center bg-no-repeat filter blur-md" style={{ backgroundImage: `url(${backgroundImage})` }}></div>
      
      {/* Navbar */}
      <nav className={`flex items-center justify-between py-4 px-4 ${isDarkMode ? 'bg-gray-800' : 'bg-pink'} text-white z-50 relative`}>
        <div className="flex items-center justify-between w-full md:w-auto">
          <Link to="/" className="text-xl font-bold">
            <img
              src={logoImage}
              alt="Logo"
              style={{ width: '50px' }}
            />
          </Link>

          {/* Hamburger Menu Icon for mobile */}
          <button
            onClick={toggleMenu}
            className="md:hidden ml-auto focus:outline-none"
          >
            <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} size="3x" />
          </button>
        </div>

        {/* Links - shown only when menu is open on mobile or always on desktop */}
        <ul
          className={`flex-col md:flex-row md:flex md:items-center md:space-x-4 space-y-4 md:space-y-0 mt-4 md:mt-0 ${
            isMenuOpen ? 'flex' : 'hidden'
          }`}
        >
          <li>
            <Link
              to="/projects"
              className={`px-6 py-2 rounded-full ${isDarkMode ? 'bg-lightcoral' : 'bg-lightcoral'} hover:bg-coral text-white transition-colors duration-300`}
              onClick={toggleMenu}
            >
              Projects
            </Link>
          </li>
          <li>
            <Link
              to="/education"
              className={`px-6 py-2 rounded-full ${isDarkMode ? 'bg-lightcoral' : 'bg-lightcoral'} hover:bg-coral text-white transition-colors duration-300`}
              onClick={toggleMenu}
            >
              Education
            </Link>
          </li>
          <li>
            <Link
              to="/experience"
              className={`px-6 py-2 rounded-full ${isDarkMode ? 'bg-lightcoral' : 'bg-lightcoral'} hover:bg-coral text-white transition-colors duration-300`}
              onClick={toggleMenu}
            >
              Experience
            </Link>
          </li>
          <li>
            <Link
              to="/skills"
              className={`px-6 py-2 rounded-full ${isDarkMode ? 'bg-lightcoral' : 'bg-lightcoral'} hover:bg-coral text-white transition-colors duration-300`}
              onClick={toggleMenu}
            >
              Skills
            </Link>
          </li>
          <li>
            <Link
              to="/links"
              className={`px-6 py-2 rounded-full ${isDarkMode ? 'bg-lightcoral' : 'bg-lightcoral'} hover:bg-coral text-white transition-colors duration-300`}
              onClick={toggleMenu}
            >
              Get in touch
            </Link>
          </li>
        </ul>

        {/* Theme Toggle Button */}
        <button
          onClick={toggleTheme}
          className="hidden md:block ml-4 px-4 py-2 rounded-full"
        >
          <FontAwesomeIcon icon={isDarkMode ? faSun : faMoon} size="2x" className={`${isDarkMode ? 'text-lightcoral' : 'text-lightcoral'}`} />
        </button>
      </nav>

      {/* Content */}
      <div className={`relative ml-4 sm:ml-8 mt-12 max-w-3xl p-6 sm:p-4 ${isDarkMode ? 'bg-gray-800 text-white'  : 'bg-white'} bg-opacity-80 rounded-lg shadow-lg`}>
        <div className="text-left">
          <h1 className="text-4xl sm:text-5xl font-bold mb-3">Yaseera Tabassum</h1>
          <h2 className="text-xl sm:text-2xl font-bold mb-6">Graduate with Bachelor of Business (HRM) and Tech Skills💻</h2>
        </div>
        <p className="text-lg sm:text-xl leading-relaxed mb-6 font-semibold">
        Hello! 👋🏻 I’m Yaseera Tabassum, a recent graduate with a strong foundation in management, human resource practices, and technology. I completed my BBA degree with a focus on Human Resource Management, where I gained deep insights into organizational behavior, employee relations, and management processes. Alongside, I pursued a diploma in Computer Science, which equipped me with essential technical skills.   </p>
        <p className="text-lg sm:text-xl leading-relaxed mb-6 font-semibold">
        My diverse educational background allows me to approach challenges from both a management and a technical perspective. Whether it’s overseeing HR operations or contributing to tech projects, I’m confident in my ability to add value to any team. As I step into the professional world, I’m eager to find opportunities where I can apply my knowledge and skills. I’m open to roles in both management and tech fields and am excited to start my career journey.
        </p>
        <div className="flex flex-col sm:flex-row gap-4">
          {['Projects', 'Education', 'Experience', 'Links'].map((text) => (
            <Link
              key={text}
              to={`/${text.toLowerCase().replace(/ /g, '-')}`}
              className={`px-6 py-2 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-lightcoral text-black'} font-semibold rounded hover:bg-coral`}
            >
              {text}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
