import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faSun, faMoon, faExternalLinkAlt, faRobot, faClipboard, faGamepad, faClock, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

const Projects = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleTheme = () => setIsDarkMode(!isDarkMode);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const backgroundImage = isDarkMode ? '/images/bgdark.jpg' : '/images/bg.jpg';
  const logoImage = isDarkMode ? '/images/ydark.jpg' : '/images/y.jpg';

  return (
    <div className={`relative flex flex-col min-h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-black'}`}>
      <div className="absolute inset-0 bg-cover bg-center bg-no-repeat filter blur-md" style={{ backgroundImage: `url(${backgroundImage})` }}></div>

      {/* Navbar */}
      <nav className={`flex items-center justify-between py-4 px-4 ${isDarkMode ? 'bg-gray-800' : 'bg-pink'} text-white z-50 relative`}>
        <div className="flex items-center justify-between w-full md:w-auto">
          <Link to="/" className="text-xl font-bold">
            <img
              src={logoImage}
              alt="Logo"
              style={{ width: '50px' }}
            />
          </Link>

          {/* Hamburger Menu Icon for mobile */}
          <button
            onClick={toggleMenu}
            className="md:hidden ml-auto focus:outline-none"
          >
            <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} size="3x" />
          </button>
        </div>

        {/* Links - shown only when menu is open on mobile or always on desktop */}
        <ul
          className={`flex-col md:flex-row md:flex md:items-center md:space-x-4 space-y-4 md:space-y-0 mt-4 md:mt-0 ${
            isMenuOpen ? 'flex' : 'hidden'
          }`}
        >
          <li>
            <Link
              to="/"
              className={`px-6 py-2 rounded-full ${isDarkMode ? 'bg-lightcoral' : 'bg-lightcoral'} hover:bg-coral text-white transition-colors duration-300`}
              onClick={toggleMenu}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/projects"
              className={`px-6 py-2 rounded-full ${isDarkMode ? 'bg-lightcoral' : 'bg-lightcoral'} hover:bg-coral text-white transition-colors duration-300`}
              onClick={toggleMenu}
            >
              Projects
            </Link>
          </li>
          <li>
            <Link
              to="/education"
              className={`px-6 py-2 rounded-full ${isDarkMode ? 'bg-lightcoral' : 'bg-lightcoral'} hover:bg-coral text-white transition-colors duration-300`}
              onClick={toggleMenu}
            >
              Education
            </Link>
          </li>
          <li>
            <Link
              to="/skills"
              className={`px-6 py-2 rounded-full ${isDarkMode ? 'bg-lightcoral' : 'bg-lightcoral'} hover:bg-coral text-white transition-colors duration-300`}
              onClick={toggleMenu}
            >
              Skills
            </Link>
          </li>
          <li>
            <Link
              to="/links"
              className={`px-6 py-2 rounded-full ${isDarkMode ? 'bg-lightcoral' : 'bg-lightcoral'} hover:bg-coral text-white transition-colors duration-300`}
              onClick={toggleMenu}
            >
              Get in touch
            </Link>
          </li>
        </ul>

        {/* Theme Toggle Button */}
        <button
          onClick={toggleTheme}
          className="hidden md:block ml-4 px-4 py-2 rounded-full"
        >
          <FontAwesomeIcon icon={isDarkMode ? faSun : faMoon} size="2x" className={`${isDarkMode ? 'text-lightcoral' : 'text-lightcoral'}`} />
        </button>
      </nav>

      {/* Projects Content */}
      <div className="relative z-10 flex flex-col items-center justify-center py-12 bg-cover bg-center" style={{ backgroundImage: 'url(/path-to-your-background-image.jpg)' }}>
      <div className={`container mx-auto px-6 py-8 rounded-lg shadow-lg ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'} bg-opacity-0`}>
        <h1 className="text-4xl font-bold text-center mb-8">Projects</h1>
        <div className="space-y-8">
          {/* Project 1 */}
          <div className={`p-6 rounded-lg shadow flex justify-between items-center ${isDarkMode ? 'bg-gray-700' : 'bg-white'} bg-opacity-0`}>
            <div>
              <h2 className="text-2xl font-semibold mb-2">Web Compiler</h2>
              <p className="text-lg mb-2">
                Created a web compiler using Node.js, Express.js, and React which compiles C, C++, Python, JS, and Bash.
              </p>
              <a href="https://yaseeras-compiler.vercel.app/" target="_blank" rel="noopener noreferrer" className="text-lightcoral hover:underline flex items-center">
                Deployed Link
                <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2 text-[20px]" style={{ color: 'lightcoral' }} />
              </a>
            </div>
            <a href="https://compiler-yaseera.vercel.app" target="_blank" rel="noopener noreferrer" className="text-lightcoral hover:underline flex items-center">
              <FontAwesomeIcon icon={faRobot} className="text-[60px] ml-4" style={{ color: 'lightcoral' }} />
            </a>
          </div>

          {/* Project 2 */}
          <div className={`p-6 rounded-lg shadow flex justify-between items-center ${isDarkMode ? 'bg-gray-700' : 'bg-white'} bg-opacity-0`}>
            <div>
              <h2 className="text-2xl font-semibold mb-2">Tasky App</h2>
              <p className="text-lg mb-2">
                Created a tasky application using Node.js, React, and Core UI for managing tasks, which sends automated reminder SMS and reminder Emails.
              </p>
              <a href="https://tasky-app.vercel.app" target="_blank" rel="noopener noreferrer" className="text-lightcoral hover:underline flex items-center">
                Deployed Link
                <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2 text-[20px]" style={{ color: 'lightcoral' }} />
              </a>
            </div>
            <a href="https://tasky-app.vercel.app" target="_blank" rel="noopener noreferrer" className="text-lightcoral hover:underline flex items-center">
              <FontAwesomeIcon icon={faClipboard} className="text-[60px] ml-4" style={{ color: 'lightcoral' }} />
            </a>
          </div>

          {/* Project 3: Tic Tac Toe */}
          <div className={`p-6 rounded-lg shadow flex justify-between items-center ${isDarkMode ? 'bg-gray-700' : 'bg-white'} bg-opacity-0`}>
            <div>
              <h2 className="text-2xl font-semibold mb-2">Tic Tac Toe</h2>
              <p className="text-lg mb-2">
                Created a Tic Tac Toe game using HTML, JS, and CSS.
              </p>
              <a href="https://yaseeras-tic-tac-toe-dom.vercel.app/" target="_blank" rel="noopener noreferrer" className="text-lightcoral hover:underline flex items-center">
                Deployed Link
                <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2 text-[20px]" style={{ color: 'lightcoral' }} />
              </a>
            </div>
            <a href="https://yaseeras-tic-tac-toe-dom.vercel.app/" target="_blank" rel="noopener noreferrer" className="text-lightcoral hover:underline flex items-center">
              <FontAwesomeIcon icon={faGamepad} className="text-[60px] ml-4" style={{ color: 'lightcoral' }} />
            </a>
          </div>

          {/* Project 4: Timer App */}
          <div className={`p-6 rounded-lg shadow flex justify-between items-center ${isDarkMode ? 'bg-gray-700' : 'bg-white'} bg-opacity-0`}>
            <div>
              <h2 className="text-2xl font-semibold mb-2">Timer App</h2>
              <p className="text-lg mb-2">
                Created a timer app using HTML, CSS, and JS with start, pause, and stop functionality.
              </p>
              <a href="https://timer-git-main-tabassumingggs-projects.vercel.app/" target="_blank" rel="noopener noreferrer" className="text-lightcoral hover:underline flex items-center">
                Deployed Link
                <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2 text-[20px]" style={{ color: 'lightcoral' }} />
              </a>
            </div>
            <a href="https://timer-git-main-tabassumingggs-projects.vercel.app/" target="_blank" rel="noopener noreferrer" className="text-lightcoral hover:underline flex items-center">
              <FontAwesomeIcon icon={faClock} className="text-[60px] ml-4" style={{ color: 'lightcoral' }} />
            </a>
          </div>

          {/* Project 5: Know Your Age App */}
          <div className={`p-6 rounded-lg shadow flex justify-between items-center ${isDarkMode ? 'bg-gray-700' : 'bg-white'} bg-opacity-0`}>
            <div>
              <h2 className="text-2xl font-semibold mb-2">Know Your Age App</h2>
              <p className="text-lg mb-2">
                Created a Know Your Age app using HTML, CSS, and JS.
              </p>
              <a href="https://yaseeras-know-your-age.vercel.app/" target="_blank" rel="noopener noreferrer" className="text-lightcoral hover:underline flex items-center">
                Deployed Link
                <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2 text-[20px]" style={{ color: 'lightcoral' }} />
              </a>
            </div>
            <a href="https://yaseeras-know-your-age.vercel.app/" target="_blank" rel="noopener noreferrer" className="text-lightcoral hover:underline flex items-center">
              <FontAwesomeIcon icon={faCalendarAlt} className="text-[60px] ml-4" style={{ color: 'lightcoral' }} />
            </a>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Projects;
